import { useFlags } from 'flagsmith/react';
import { FeatureFlags } from '@lawnstarter/customer-modules/enums';

import { LongGrassQuoteReportScreenV2 } from '@src/pages';

import { LongGrassQuoteReportScreen } from '../LongGrassQuoteReportScreen/LongGrassQuoteReportScreen';

export function LongGrassQuoteReportScreenWrapper() {
  const { enabled: isLongGrassQuoteReportScreenV2 } = useFlags([
    FeatureFlags.SERVICES_TAB_IMPROVEMENTS_WEB,
  ])[FeatureFlags.SERVICES_TAB_IMPROVEMENTS_WEB];

  return isLongGrassQuoteReportScreenV2 ? (
    <LongGrassQuoteReportScreenV2 />
  ) : (
    <LongGrassQuoteReportScreen />
  );
}
