import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';

import { useAppDownloadModal } from '@src/hooks/useAppDownloadModal';

export function LongGrassFeeReportScreen() {
  const { showAppDownloadModal } = useAppDownloadModal();
  const navigate = useNavigate();

  useEffect(() => {
    showAppDownloadModal();
  }, [showAppDownloadModal]);

  navigate(WebRoutes.services);

  return null;
}
