import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useSavePoolQuestionController } from '@lawnstarter/customer-modules/controllers';
import { t } from '@lawnstarter/customer-modules/services';
import { setFontWeight, useAppTheme } from '@lawnstarter/ls-react-common';
import {
  Button,
  ImageUploadInput,
  Text,
  TouchableRipple,
} from '@lawnstarter/ls-react-common/atoms';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import type { DetailsForPoolCleaning } from '@src/components/organisms/PropertyDetailsQuestionManager/types';

export function PoolImage() {
  const theme = useAppTheme();
  const errorModal = useModal(ModalTemplate.ACTION_CONFIRMATION);

  const { control, handleSubmit } = useForm<{ poolImage: string }>();
  const { updateAction, isUpdating } = useSavePoolQuestionController<DetailsForPoolCleaning>({
    errorModal,
    key: 'poolImage',
  });

  const updateImage = useCallback(
    async (data: { poolImage?: any } = {}) => {
      if (Array.isArray(data?.poolImage)) {
        data.poolImage = data.poolImage[0];
      }

      await updateAction(data?.poolImage);
    },
    [updateAction],
  );

  return (
    <div className="flex flex-col flex-1 gap-2 md:flex-row">
      <div className="flex flex-col gap-2 md:flex-1">
        <Text variant="headlineSmall" style={{ ...setFontWeight('600') }}>
          {t('forms.poolQuestions.poolImage')}
        </Text>
        <Text>{t('forms.poolQuestions.poolImageDescription')}</Text>
      </div>

      <div className="flex flex-col gap-2 flex-1">
        <div className="flex flex-col gap-2">
          <ImageUploadInput
            singleUpload
            name="poolImage"
            control={control}
            isUploading={isUpdating}
            rules={{ required: true }}
            addButton={{ text: 'Pool photo' }}
            uploadText={t('forms.upload.uploading')}
            label={t('forms.poolQuestions.pickOneImage')}
            tooLargeFilesText={t('forms.upload.fileTooLarge')}
          />

          <TouchableRipple onPress={updateImage}>
            <Text
              variant="titleMedium"
              style={{
                color: theme.colors.gray.tertiary,
                paddingVertical: theme.spacing.s6,
                textAlign: 'center',
              }}
            >
              {t('forms.poolQuestions.poolImageUnableToProvide')}
            </Text>
          </TouchableRipple>
        </div>

        <div className="flex flex-col self-end justify-end items-end flex-1 w-full">
          <Button
            mode="contained"
            loading={isUpdating}
            disabled={isUpdating}
            style={{ width: '100%' }}
            trackID="pool_image_screen-next"
            onPress={handleSubmit(updateImage)}
          >
            {t('next')}
          </Button>
        </div>
      </div>
    </div>
  );
}
