import { useFlags } from 'flagsmith/react';
import { useMatch } from 'react-router-dom';
import { FeatureFlags, WebRoutes } from '@lawnstarter/customer-modules/enums';
import { useServicesMissingPropertyDetails } from '@lawnstarter/customer-modules/hooks';
import { t } from '@lawnstarter/customer-modules/services';
import { properties_currentPropertySelector } from '@lawnstarter/customer-modules/stores/modules';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Text } from '@lawnstarter/ls-react-common/atoms';
import { Notice as NoticeType } from '@lawnstarter/ls-react-common/enums';
import { Notice } from '@lawnstarter/ls-react-common/organisms';

import { StyledResponsiveNotice } from '@src/components/atoms';
import { useRouteNavigation, useSelector } from '@src/hooks';

import type { Property } from '@lawnstarter/customer-modules/types';
import type { ServiceType } from '@lawnstarter/ls-react-common/enums';

export function MissingServiceDetailsNotice() {
  const theme = useAppTheme();
  const property: Property = useSelector(properties_currentPropertySelector);

  const { navigate } = useRouteNavigation();

  const flags = useFlags([FeatureFlags.POOL_CLEANING_MINIMUM_EXPERIENCE_M1]);

  const servicesMissingDetails = useServicesMissingPropertyDetails({
    isEnabled: flags?.[FeatureFlags.POOL_CLEANING_MINIMUM_EXPERIENCE_M1]?.enabled,
  });

  const showMissingServiceDetailsNotice =
    !useMatch(WebRoutes.propertyDetailsQuestions) && servicesMissingDetails.length > 0;

  function renderNoticeForService(service: ServiceType) {
    return (
      <Notice type={NoticeType.Info} key={service}>
        <StyledResponsiveNotice>
          <Text>{t(`notices.missingServiceDetails.${service}`) ?? ''}</Text>

          <Button
            trackID="missing-property-service-details-notice_update-now"
            textColor={theme.colors.blue.primary}
            style={{ alignSelf: 'flex-end' }}
            onPress={() =>
              navigate(WebRoutes.propertyDetailsQuestions, {
                params: { serviceStrId: service, propertyId: property?.id },
              })
            }
          >
            {t('updateNow')}
          </Button>
        </StyledResponsiveNotice>
      </Notice>
    );
  }

  return (
    showMissingServiceDetailsNotice &&
    servicesMissingDetails?.map((service) => renderNoticeForService(service))
  );
}
