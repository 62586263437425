import { useFlags } from 'flagsmith/react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { FeatureFlags, WebRoutes } from '@lawnstarter/customer-modules/enums';
import {
  checkboxParser,
  formatStumpGrindingOrRemovalDescription,
  getStumpGrindingOrRemovalFieldData,
  MQHowQuotingWorksModal,
  MQSubmitSuccessModal,
} from '@lawnstarter/customer-modules/helpers';
import {
  getTrackingEventSource,
  useOrderServiceViewedTracking,
} from '@lawnstarter/customer-modules/hooks';
import { t } from '@lawnstarter/customer-modules/services';
import {
  orderServices_submitQuote,
  properties_currentPropertyIdSelector,
} from '@lawnstarter/customer-modules/stores/modules';
import { YardLocations } from '@lawnstarter/ls-constants';
import { Button } from '@lawnstarter/ls-react-common/atoms';
import { ServiceType } from '@lawnstarter/ls-react-common/enums';
import { useApiTracking, useModal } from '@lawnstarter/ls-react-common/hooks';
import { ModalTemplate, StumpGrindingOrRemovalForm } from '@lawnstarter/ls-react-common/organisms';

import { OrderServiceHeader, StyledOrderFormContainer } from '@src/components';
import { useDispatch, useRouteNavigation, useSelector } from '@src/hooks';
import { errorService } from '@src/services';

import type {
  StumpGrindingOrRemovalFormData,
  StumpGrindingOrRemovalFormFieldData,
} from '@lawnstarter/ls-react-common/organisms';

export function OrderStumpGrindingOrRemovalScreen() {
  const apiTracking = useApiTracking()!;
  const location = useLocation();

  const isUpdating = useSelector(({ orderServices }) => orderServices.loadingStatus.isUpdating);

  const stepModal = useModal(ModalTemplate.STEPS);
  const QuoteSubmittedModal = useModal(ModalTemplate.ACTION_CONFIRMATION);

  const { control, handleSubmit } = useForm<StumpGrindingOrRemovalFormData>();
  const fieldData: StumpGrindingOrRemovalFormFieldData = {
    ...getStumpGrindingOrRemovalFieldData(),
    images: {
      ...getStumpGrindingOrRemovalFieldData().images,
      isUploading: false,
    },
  };

  const propertyId = useSelector(properties_currentPropertyIdSelector);
  const dispatch = useDispatch();
  const { navigate } = useRouteNavigation();

  const carouselAbTest = useFlags([FeatureFlags.RECOMMENDATION_CAROUSEL_AB_TEST])[
    FeatureFlags.RECOMMENDATION_CAROUSEL_AB_TEST
  ];

  const source = carouselAbTest.enabled
    ? getTrackingEventSource(location.state?.source, carouselAbTest.value)
    : undefined;

  useOrderServiceViewedTracking(
    apiTracking,
    ServiceType.StumpGrindingOrRemoval,
    errorService,
    source,
  );

  const submit = async (data: StumpGrindingOrRemovalFormData) => {
    const { success } = await dispatch(
      orderServices_submitQuote({
        updatedFields: {
          property_id: propertyId,
          service_id: 4809,
          service_str_id: ServiceType.StumpGrindingOrRemoval,
          budget: data.budget,
          pendingImages: data.images,
          locations: checkboxParser(data.position, YardLocations),
          desiredWorkDetails: formatStumpGrindingOrRemovalDescription(data),
        },
        source,
      }),
    );

    if (success) {
      return QuoteSubmittedModal.show(MQSubmitSuccessModal(navigate, WebRoutes.services));
    }
  };

  return (
    <StyledOrderFormContainer>
      <OrderServiceHeader
        section={t('forms.quote.request')}
        serviceName={t('forms.stumpGrindingOrRemoval.name')}
      />

      <StumpGrindingOrRemovalForm
        control={control}
        fieldData={fieldData}
        isUpdating={isUpdating}
        infoModal={() => stepModal.show(MQHowQuotingWorksModal())}
      />

      <Button
        trackID="submit"
        testID="submit"
        mode="contained"
        onPress={handleSubmit(submit)}
        loading={isUpdating}
        disabled={isUpdating}
      >
        {t('recommendations.getQuote')}
      </Button>
    </StyledOrderFormContainer>
  );
}
