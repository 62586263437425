import { PropertyDetailsActiveServices } from '@lawnstarter/customer-modules/enums';

import {
  PoolComment,
  PoolFiltrationType,
  PoolImage,
  PoolMaterial,
  PoolSize,
  PoolSystem,
} from '@src/pages/Authorized/Profile/PropertyDetailsQuestionsScreen/poolCleaning';

export const questionFlowsByService = {
  // The order matters!
  [PropertyDetailsActiveServices.PoolCleaning]: {
    poolSystem: <PoolSystem />,
    poolSize: <PoolSize />,
    poolFiltrationType: <PoolFiltrationType />,
    poolMaterial: <PoolMaterial />,
    poolImage: <PoolImage />,
    comments: <PoolComment />,
  },
} as const;
