import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { getBrand, getSupportWebRoute } from '@lawnstarter/customer-modules/helpers';
import { t } from '@lawnstarter/customer-modules/services';

import { useRouteCreator } from '@src/hooks';
import {
  AuthorizedServicesTemplateWrapper,
  CancelServiceScreen,
  ChangeFrequencyScreen,
  ChangeMyProviderScreen,
  ChangeScheduleScreen,
  ConfirmCancelScreen,
  ContactSupportScreen,
  DisputeConfirmScreen,
  DisputeLocationScreen,
  DisputeNotesScreen,
  DisputePhotosScreen,
  DisputeReasonScreen,
  HelpAndSupportScreen,
  LearnAboutLongGrassPricing,
  LongGrassQuoteReportScreenWrapper,
  OneTimeRescheduleScreen,
  PauseScreen,
  PendingInstantQuoteScreen,
  QuoteDetailsScreen,
  ReportDamageScreen,
  ServiceDetailsScreen,
  ServiceQuoteDetailsScreen,
  SkipServiceScreen,
  UnpauseScreen,
} from '@src/pages';
import { LongGrassFeeReportScreen } from '@src/pages/Authorized/Services/LongGrassFeeReportScreen';
import { OnlyProgrammaticAccess } from '@src/routers/guards';

import type { User } from '@lawnstarter/ls-react-common/types';
import type { RouteObject } from 'react-router-dom';

export function ServicesRouter({ user }: { user: User }): RouteObject[] {
  const brand = getBrand();
  const { createRoute } = useRouteCreator();

  return [
    {
      path: WebRoutes.services,
      element: <AuthorizedServicesTemplateWrapper user={user} />,
      children: [
        createRoute({
          index: true,
          component: <ServiceDetailsScreen />,
        }),
        createRoute({
          key: 'regular-details',
          path: WebRoutes.serviceDetail,
          component: <ServiceDetailsScreen />,
        }),
        createRoute({
          key: 'recommended',
          path: WebRoutes.recommendedQuoteDetails,
          component: <ServiceDetailsScreen />,
        }),
        createRoute({
          key: 'required',
          path: WebRoutes.requiredQuoteDetails,
          component: <ServiceDetailsScreen />,
        }),
        createRoute({
          path: WebRoutes.manualQuoteDetails,
          component: <QuoteDetailsScreen />,
        }),
        createRoute({
          path: WebRoutes.instantQuoteDetails,
          component: <QuoteDetailsScreen />,
        }),
        createRoute({
          path: WebRoutes.pendingInstantQuote,
          component: <PendingInstantQuoteScreen />,
        }),
        createRoute({
          path: WebRoutes.serviceQuoteDetails,
          component: <ServiceQuoteDetailsScreen />,
        }),
        createRoute({
          path: WebRoutes.changeSchedule,
          component: <ChangeScheduleScreen />,
        }),
        createRoute({
          title: t('changeFrequency.title'),
          path: WebRoutes.changeFrequency,
          component: <ChangeFrequencyScreen />,
        }),
        createRoute({
          path: WebRoutes.learnMoreLongGrassPricing,
          component: <LearnAboutLongGrassPricing />,
        }),
        createRoute({
          path: WebRoutes.longGrassFeeReport,
          component: <LongGrassFeeReportScreen />,
        }),
        createRoute({
          path: WebRoutes.longGrassQuoteReport,
          component: <LongGrassQuoteReportScreenWrapper />,
        }),
        createRoute({
          path: WebRoutes.disputePhotos,
          component: <OnlyProgrammaticAccess component={<DisputePhotosScreen />} />,
        }),
        createRoute({
          path: WebRoutes.disputeLocation,
          component: <OnlyProgrammaticAccess component={<DisputeLocationScreen />} />,
        }),
        createRoute({
          path: WebRoutes.disputeNotes,
          component: <OnlyProgrammaticAccess component={<DisputeNotesScreen />} />,
        }),
        createRoute({
          path: WebRoutes.disputeReason,
          component: <OnlyProgrammaticAccess component={<DisputeReasonScreen />} />,
        }),
        createRoute({
          path: WebRoutes.disputeConfirm,
          component: <OnlyProgrammaticAccess component={<DisputeConfirmScreen />} />,
        }),
        createRoute({
          title: t('supportActions.propertyDamage'),
          path: WebRoutes.reportDamage,
          component: <ReportDamageScreen />,
        }),
      ],
    },
    {
      path: WebRoutes.support,
      element: <AuthorizedServicesTemplateWrapper user={user} />,
      children: [
        createRoute({
          title: t('skipNextMow'),
          path: WebRoutes.skipService,
          component: <OnlyProgrammaticAccess component={<SkipServiceScreen />} />,
        }),
        createRoute({
          title: t('changeMyPro.pageTitle'),
          path: WebRoutes.changeMyPro,
          component: <OnlyProgrammaticAccess component={<ChangeMyProviderScreen />} />,
        }),
        createRoute({
          title: t('oneTimeReschedule.rescheduleUpcomingService'),
          path: WebRoutes.oneTimeReschedule,
          component: <OnlyProgrammaticAccess component={<OneTimeRescheduleScreen />} />,
        }),
        createRoute({
          title: t('contactSupport.screenTitle'),
          path: getSupportWebRoute(brand.slug),
          component: <OnlyProgrammaticAccess component={<ContactSupportScreen />} />,
        }),
        createRoute({
          index: true,
          title: t('helpAndSupport.screenTitle'),
          component: <HelpAndSupportScreen />,
        }),
        createRoute({
          title: t('helpAndSupport.screenTitle'),
          path: WebRoutes.supportFromCancel,
          component: <HelpAndSupportScreen />,
        }),
        createRoute({
          title: t('pauseService'),
          path: WebRoutes.pauseService,
          component: <PauseScreen />,
        }),
        createRoute({
          path: WebRoutes.cancelService,
          component: <CancelServiceScreen />,
        }),
        createRoute({
          title: t('cancelService.confirmCancel'),
          path: WebRoutes.confirmCancel,
          component: <ConfirmCancelScreen />,
        }),
        createRoute({
          title: t('supportActions.reactivateEarlier.title'),
          path: WebRoutes.resumePausedService,
          component: <UnpauseScreen />,
        }),
      ],
    },
  ];
}
