import { useFlags } from 'flagsmith/react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { FeatureFlags, WebRoutes } from '@lawnstarter/customer-modules/enums';
import {
  checkboxParser,
  formatFlowerPlantingDescription,
  getFlowerPlantingFieldData,
  MQHowQuotingWorksModal,
  MQSubmitSuccessModal,
} from '@lawnstarter/customer-modules/helpers';
import {
  getTrackingEventSource,
  useOrderServiceViewedTracking,
} from '@lawnstarter/customer-modules/hooks';
import { t } from '@lawnstarter/customer-modules/services';
import {
  orderServices_isUpdating,
  orderServices_submitQuote,
  properties_currentPropertyIdSelector,
} from '@lawnstarter/customer-modules/stores/modules';
import { YardLocations } from '@lawnstarter/ls-constants';
import { Button } from '@lawnstarter/ls-react-common/atoms';
import { ServiceType } from '@lawnstarter/ls-react-common/enums';
import { useApiTracking, useModal } from '@lawnstarter/ls-react-common/hooks';
import { FlowerPlantingForm, ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import { OrderServiceHeader, StyledOrderFormContainer } from '@src/components';
import { useDispatch, useRouteNavigation, useSelector } from '@src/hooks';
import { errorService } from '@src/services';

import type {
  FlowerPlantingFormData,
  FlowerPlantingFormFieldData,
} from '@lawnstarter/ls-react-common/organisms';

export function OrderFlowerPlantingScreen() {
  const apiTracking = useApiTracking()!;
  const location = useLocation();

  const isUpdating = useSelector(orderServices_isUpdating);

  const stepModal = useModal(ModalTemplate.STEPS);
  const QuoteSubmittedModal = useModal(ModalTemplate.ACTION_CONFIRMATION);

  const { control, handleSubmit, watch } = useForm<FlowerPlantingFormData>({
    defaultValues: {
      frequency: 84,
    },
  });

  const fieldData: FlowerPlantingFormFieldData = {
    ...getFlowerPlantingFieldData(),
    images: {
      ...getFlowerPlantingFieldData().images,
      isUploading: false,
    },
  };
  const containerTypes = watch('containerType', []);
  const withContainerTypeOther = containerTypes.some((value) => value === 'other');

  const { navigate } = useRouteNavigation();

  const carouselAbTest = useFlags([FeatureFlags.RECOMMENDATION_CAROUSEL_AB_TEST])[
    FeatureFlags.RECOMMENDATION_CAROUSEL_AB_TEST
  ];

  const source = carouselAbTest.enabled
    ? getTrackingEventSource(location.state?.source, carouselAbTest.value)
    : undefined;

  useOrderServiceViewedTracking(apiTracking, ServiceType.FlowerPlanting, errorService, source);

  const propertyId = useSelector(properties_currentPropertyIdSelector);
  const dispatch = useDispatch();

  const submit = async (data: FlowerPlantingFormData) => {
    const { success } = await dispatch(
      orderServices_submitQuote({
        updatedFields: {
          property_id: propertyId,
          service_id: 4812,
          service_str_id: ServiceType.FlowerPlanting,
          budget: data.budget,
          pendingImages: data.images,
          locations: checkboxParser(data.position, YardLocations),
          desiredWorkDetails: formatFlowerPlantingDescription(data),
          cycle: data.frequency,
        },
        source,
      }),
    );

    if (success) {
      return QuoteSubmittedModal.show(MQSubmitSuccessModal(navigate, WebRoutes.services));
    }
  };

  return (
    <StyledOrderFormContainer>
      <OrderServiceHeader
        section={t('forms.quote.request')}
        serviceName={t('forms.flowerPlanting.name')}
      />

      <FlowerPlantingForm
        control={control}
        fieldData={fieldData}
        isUpdating={isUpdating}
        infoModal={() => stepModal.show(MQHowQuotingWorksModal())}
        withContainerTypeOther={withContainerTypeOther}
      />

      <Button
        trackID="submit"
        testID="submit"
        mode="contained"
        onPress={handleSubmit(submit)}
        loading={isUpdating}
        disabled={isUpdating}
      >
        {t('recommendations.getQuote')}
      </Button>
    </StyledOrderFormContainer>
  );
}
