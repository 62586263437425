import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { RouteTitles } from '@lawnstarter/customer-modules/enums';
import { useBootstrap } from '@lawnstarter/customer-modules/hooks';

import {
  EmailsNotGoingThroughNotice,
  MissingPasswordNotice,
  MissingServiceDetailsNotice,
  PaymentNotice,
} from '@src/components';
import { useAppContext } from '@src/contexts';

import { DesktopHeader } from './DesktopHeader';
import { MobileHeader } from './MobileHeader';
import { StyledNotices } from './styles';

import type { WebRoutes } from '@lawnstarter/customer-modules/enums';
import type { CurrentTabProps } from '@src/types';

export function Header({ currentTab }: CurrentTabProps) {
  // loading initial data here ensures that
  // all requests will have the necessary
  // information to make the requests
  useBootstrap();

  const location = useLocation();
  const { setTitle } = useAppContext();

  // Set title based on the current route
  useLayoutEffect(() => {
    const alteredPath = Object.keys(RouteTitles).find((route) => {
      const regex = new RegExp(`^${route.replace(/:[^\s/]+/g, '([\\w-]+)')}$`);
      return regex.test(location.pathname);
    });

    const title = RouteTitles[alteredPath as WebRoutes];
    title && setTitle(title);
  }, [location.pathname, setTitle]);

  return (
    <>
      <MobileHeader />
      <DesktopHeader currentTab={currentTab} />

      <StyledNotices>
        <EmailsNotGoingThroughNotice />
        <MissingPasswordNotice />
        <MissingServiceDetailsNotice />
      </StyledNotices>
      <PaymentNotice />
    </>
  );
}
