import { useCallback } from 'react';
import { useOrderPoolFlowController } from '@lawnstarter/customer-modules/controllers';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { ScrollView } from '@lawnstarter/ls-react-common/atoms';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import { OrderDetailsConfirmation, ResponsiveRenderer } from '@src/components';
import { useRouteNavigation } from '@src/hooks';

import type { ServiceType } from '@lawnstarter/ls-react-common/enums';

export const PoolOrderConfirmationScreen = () => {
  const { navigate } = useRouteNavigation();
  const modal = useModal(ModalTemplate.ACTION_CONFIRMATION);
  const errorModal = useModal(ModalTemplate.ACTION_CONFIRMATION);

  const { selectedPlan, serviceInfo, onConfirmOrderingPlan, submittingOrder } =
    useOrderPoolFlowController({
      confirmationModal: {
        instance: modal,
        onSuccess: () => navigate(WebRoutes.services, { options: { replace: true } }),
      },
      errorModal: {
        instance: errorModal,
      },
    });

  const renderContent = useCallback(
    () => (
      <OrderDetailsConfirmation
        withPaymentSection
        isUpdating={submittingOrder}
        onSubmit={onConfirmOrderingPlan}
        priceInfo={selectedPlan?.priceInfo}
        origin="order_pool_cleaning_screen"
        service={selectedPlan?.service as ServiceType}
        labels={{
          button: t('orderNow'),
          footer: serviceInfo?.service.billedUpon,
          title: `${t('orderConfirmation.dueSection.dueAmountShort')} ${t('orderConfirmation.dueSection.title')}`,
        }}
      />
    ),
    [selectedPlan, serviceInfo, onConfirmOrderingPlan, submittingOrder],
  );

  return (
    <ResponsiveRenderer>
      <ResponsiveRenderer.Portable>
        <ScrollView>{renderContent()}</ScrollView>
      </ResponsiveRenderer.Portable>

      <ResponsiveRenderer.Desktop>{renderContent()}</ResponsiveRenderer.Desktop>
    </ResponsiveRenderer>
  );
};
