import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { profile_updatePassword } from '@lawnstarter/customer-modules/stores/modules';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Text } from '@lawnstarter/ls-react-common/atoms';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { ChangePasswordForm, ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import { useDispatch, useRouteNavigation } from '@src/hooks';

import { DetailsContainer, PageContainer, styles, TitleContainer } from './styles';

import type { ChangePasswordFormData } from '@lawnstarter/ls-react-common/organisms';

export function ChangePasswordScreen() {
  const theme = useAppTheme();
  const dispatch = useDispatch();
  const { navigate } = useRouteNavigation();
  const style = useMemo(() => styles(theme), [theme]);
  const modal = useModal(ModalTemplate.INFO);

  const [isUpdating, setIsUpdating] = useState(false);

  const { control, setFocus, handleSubmit, reset } = useForm<ChangePasswordFormData>({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    resolver: zodResolver(
      z
        .object({
          password: z
            .string({ required_error: t('validations.passwordRequired') })
            .min(6, t('validations.passwordMinLength', { n: 6 })),
          confirmPassword: z
            .string({ required_error: t('validations.password') })
            .min(6, t('validations.passwordMinLength', { n: 6 })),
        })
        .refine((data) => data.password === data.confirmPassword, {
          path: ['confirmPassword'],
          message: t('validations.passwordsDontMatch'),
        }),
    ),
  });

  const changePassword = async ({ password }: ChangePasswordFormData) => {
    setIsUpdating(true);
    const res = await dispatch(profile_updatePassword({ password_new: password }));
    reset();
    if (res?.success) {
      modal.show({
        content: t('profile.changePassword.passwordChanged'),
        onDismiss: () => navigate(WebRoutes.profile),
      });
    }
    setIsUpdating(false);
  };

  const renderTitle = useCallback(
    () => (
      <div className="flex flex-col justify-center h-[80px] bg-primary mb-6 pl-6">
        <Text variant="titleLarge" style={style.title}>
          {t('settings.changePassword')}
        </Text>
      </div>
    ),
    [style],
  );

  return (
    <PageContainer>
      <TitleContainer>{renderTitle()}</TitleContainer>
      <DetailsContainer>
        <div className="flex flex-col grow md:grow-0" data-testid="change-password-form">
          <ChangePasswordForm
            origin="change_password_screen"
            control={control}
            isUpdating={isUpdating}
            submit={() => handleSubmit(changePassword)}
            setFocus={setFocus}
            fieldTexts={{
              password: { label: t('password') },
              confirmPassword: { label: t('passwordConfirmation') },
            }}
          />
        </div>

        <div className="flex justify-center mt-8">
          <div className="grow md:grow-0 md:w-button-md">
            <Button
              trackID="change-password-submit"
              testID="change-password-submit"
              mode="contained"
              loading={isUpdating}
              onPress={handleSubmit(changePassword)}
            >
              {t('settings.changePassword')}
            </Button>
          </div>
        </div>
      </DetailsContainer>
    </PageContainer>
  );
}
