import { POOL_SYSTEMS } from '@lawnstarter/customer-modules/constants';
import { useSavePoolQuestionController } from '@lawnstarter/customer-modules/controllers';
import { t } from '@lawnstarter/customer-modules/services';
import { setFontWeight } from '@lawnstarter/ls-react-common';
import { Text } from '@lawnstarter/ls-react-common/atoms';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { SelectOption } from '@lawnstarter/ls-react-common/molecules';
import { ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import type { DetailsForPoolCleaning } from '@src/components/organisms/PropertyDetailsQuestionManager/types';

export function PoolSystem() {
  const errorModal = useModal(ModalTemplate.ACTION_CONFIRMATION);

  const { updateAction, isUpdating } = useSavePoolQuestionController<DetailsForPoolCleaning>({
    errorModal,
    key: 'poolSystem',
  });

  return (
    <>
      <div className="flex flex-col gap-2 md:flex-1">
        <Text
          aria-level={2}
          variant="headlineSmall"
          accessibilityRole={'header'}
          style={{ ...setFontWeight('600') }}
        >
          {t('forms.poolQuestions.poolType')}
        </Text>
        <Text>{t('forms.poolQuestions.poolTypeDescription')}</Text>
      </div>

      <div className="flex-1">
        <SelectOption options={POOL_SYSTEMS} onSelect={updateAction} disabled={isUpdating} />
      </div>
    </>
  );
}
