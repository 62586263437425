import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import {
  PropertyDetailsQuestionContextProvider,
  usePropertyDetailsQuestionContext,
} from '@lawnstarter/customer-modules/contexts';
import { t } from '@lawnstarter/customer-modules/services';
import { setFontWeight, useAppTheme } from '@lawnstarter/ls-react-common';
import { ProgressBar, Text } from '@lawnstarter/ls-react-common/atoms';

import { errorService } from '@src/services';

import { questionFlowsByService } from './services';

import type { PropertyDetailsActiveServices } from '@lawnstarter/customer-modules/enums';
import type { PropertyDetails } from '@lawnstarter/customer-modules/types';
import type { QuestionRendererProps } from './types';

export function PropertyDetailsQuestionManager<T extends PropertyDetailsActiveServices>({
  type,
  initialData,
  onCompleted,
}: {
  type: T;
  onCompleted: () => void;
  initialData?: PropertyDetails;
}) {
  const steps = questionFlowsByService[type];

  if (!steps) {
    errorService.log({ error: new Error(`No question flow found for service type: ${type}`) });
    return null;
  }

  return (
    <PropertyDetailsQuestionContextProvider steps={steps}>
      <QuestionRenderer initialData={initialData?.data} steps={steps} onCompleted={onCompleted} />
    </PropertyDetailsQuestionContextProvider>
  );
}

export function QuestionRenderer({ steps, initialData, onCompleted }: QuestionRendererProps) {
  const theme = useAppTheme();
  const { currentStep, currentState, update, goToStep } = usePropertyDetailsQuestionContext();

  const currentProgress = useMemo(() => {
    const currentStepIndex = Object.keys(steps).indexOf(currentStep.key);
    return (currentStepIndex + 1) / Object.keys(steps).length;
  }, [currentStep, steps]);

  // If we already have some questions answered we need to update the state
  useEffect(() => {
    if (_.isEmpty(currentState) && initialData) {
      update(initialData);
    }
  }, [currentState, initialData, update]);

  // Ignore steps that have been answered
  useEffect(() => {
    if (!_.isEmpty(currentState) && steps) {
      const answeredSteps = Object.keys(currentState);
      const firstUnansweredStep = Object.keys(steps).find(
        (step) => !answeredSteps.includes(step) || !currentState[step],
      );

      if (firstUnansweredStep && firstUnansweredStep !== currentStep.key) {
        return goToStep(firstUnansweredStep);
      }

      if (!firstUnansweredStep) {
        onCompleted();
      }
    }
  }, [currentState, goToStep, onCompleted, steps, currentStep.key]);

  return (
    <div className="flex flex-col flex-1">
      <div className="flex flex-col gap-2">
        <ProgressBar progress={currentProgress} />
        <Text
          variant="titleMedium"
          style={{ ...setFontWeight('600'), color: theme.colors.gray.tertiary }}
        >
          {t('optimizingService')}
        </Text>
      </div>

      <div className="mt-6 flex-1 flex flex-col">
        <div className="flex flex-col flex-1 gap-6 md:flex-row">{currentStep.screen}</div>
      </div>
    </div>
  );
}
