import { ScrollView, View } from 'react-native';
import { ActivityIndicator, Divider } from 'react-native-paper';
import { useParams } from 'react-router-dom';
import { t } from '@lawnstarter/customer-modules/services';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, RatingInput, Text } from '@lawnstarter/ls-react-common/atoms';
import { date } from '@lawnstarter/ls-react-common/filters';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { SelectOption } from '@lawnstarter/ls-react-common/molecules';
import { ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import { ModalFooter, ServiceReviewTitle, TipAmountConfirmation } from '@src/components/molecules';
import { ConfirmationType } from '@src/components/molecules/TipAmountConfirmation/enums';
import { ActionConfirmation, TipAmountOptions } from '@src/components/organisms';
import { AutoTipEnableForm, ServiceReviewCommentsForm } from '@src/components/organisms/forms';
import { DEVICE_WIDTH_BREAKPOINTS } from '@src/constants';
import { useWindowSize } from '@src/hooks';

import { issueOptions } from './constants';
import { useServiceReviewModal } from './controller';
import { useStyles } from './styles';

import type { Props as TipAmountConfirmationProps } from '@src/components/molecules/TipAmountConfirmation/types';
import type { Props } from './types';

export function ServiceReviewModal(props: Props) {
  const theme = useAppTheme();
  const { styles, ids } = useStyles(theme);
  const { width } = useWindowSize();
  const messagesModal = useModal(ModalTemplate.MESSAGES);

  const params = useParams();
  const propertyId = props.propertyId ?? params.propertyId;
  const scheduleId = props.scheduleId ?? params.scheduleId;
  const scheduleEventId = props.scheduleEventId ?? params.scheduleEventId;

  const {
    alreadyRated,
    autoTipSettings,
    hasSubmitted,
    isBadRating,
    isDisputableService,
    isGoodRating,
    isIssueRequired,
    isLoading,
    isNoTip,
    isPartnerContractor,
    issue,
    isUpdating,
    modalContext,
    name,
    onAutoTipChoice,
    onIssueSelect,
    onRatingChange,
    onSubmit,
    onTipSelect,
    rating,
    reviewId,
    schedule,
    scheduleEvent,
    servicePrice,
    showAutoTipChoice,
    showSuccessMessage,
    showTipConfirmation,
    tip,
    tipMatching,
  } = useServiceReviewModal({
    propertyId: Number(propertyId),
    scheduleId: Number(scheduleId),
    scheduleEventId: Number(scheduleEventId),
    messagesModal,
  });

  function renderSuccessMessageConfirmation() {
    if (!showSuccessMessage) {
      return null;
    }

    return (
      <ActionConfirmation
        icon="check"
        title={t('review.confirmation.title')}
        description={t('review.confirmation.description')}
        action={{
          label: t('close'),
          onPress: modalContext.hide,
        }}
      />
    );
  }

  const successMessageConfirmation = renderSuccessMessageConfirmation();
  if (successMessageConfirmation) {
    return successMessageConfirmation;
  }

  function renderServiceTitle() {
    if (rating) {
      return null;
    }

    return (
      <View style={styles.titleSection}>
        <ServiceReviewTitle scheduleEvent={scheduleEvent} />
      </View>
    );
  }

  function renderRatingInput() {
    const disabled = isUpdating || (!autoTipSettings.enabled && Boolean(showTipConfirmation));
    const isAffirmativeTitle = showTipConfirmation || isPartnerContractor;
    const label = t(`review.${isAffirmativeTitle ? 'serviceRating' : 'howWasService'}`, { name });
    const isMobile = width <= DEVICE_WIDTH_BREAKPOINTS.mobile.max;
    const starSize = (isMobile ? theme.sizing.s8 : theme.sizing.s10) - 2;

    return (
      <View dataSet={{ media: ids.ratingSection }} style={styles.ratingSection}>
        <Text dataSet={{ media: ids.ratingLabel }} style={styles.ratingLabel}>
          {label}
        </Text>

        <RatingInput
          disabled={disabled}
          onValueChange={onRatingChange}
          starSize={starSize}
          starStyle={styles.ratingStar}
          style={styles.ratingInput}
          value={rating}
        />
      </View>
    );
  }

  function renderDivider() {
    return <Divider dataSet={{ media: ids.divider }} style={styles.divider} />;
  }

  function renderIssueOptions() {
    if (!isDisputableService || !isBadRating) {
      return null;
    }

    const isMobile = width <= DEVICE_WIDTH_BREAKPOINTS.mobile.max;
    const textAlignment = isMobile ? 'center' : 'left';

    return (
      <>
        {renderDivider()}

        <View dataSet={{ media: ids.issueSection }} style={styles.issueSection}>
          <Text dataSet={{ media: ids.issueLabel }} style={styles.issueLabel} variant="titleMedium">
            {t('review.feedback')}
          </Text>

          <View
            dataSet={{ media: ids.issueOptions }}
            pointerEvents={isUpdating || hasSubmitted ? 'none' : 'auto'}
            style={styles.issueOptions}
          >
            <SelectOption
              key={issue}
              onSelect={onIssueSelect}
              options={issueOptions}
              selected={issue}
              textAlignment={textAlignment}
              trackID="service_review_modal-issues"
            />
          </View>
        </View>
      </>
    );
  }

  function renderTipOptions() {
    if (!isGoodRating || showTipConfirmation || isPartnerContractor) {
      return null;
    }

    return (
      <>
        {renderDivider()}

        <View pointerEvents={isUpdating || hasSubmitted ? 'none' : 'auto'}>
          <TipAmountOptions
            allowNoTip
            onSelect={onTipSelect}
            selected={tip}
            servicePrice={servicePrice}
            tipMatching={tipMatching}
          />
        </View>
      </>
    );
  }

  function renderAutoTipEnableForm() {
    if (!showAutoTipChoice || isNoTip) {
      return null;
    }

    const amountOrPercentage = tip.percentage
      ? { percentage: tip.percentage / 100 }
      : { amount: Math.round(tip.amount! * 100) };

    return <AutoTipEnableForm onDismiss={onAutoTipChoice} {...amountOrPercentage} />;
  }

  function renderCommentsForm() {
    if (!isGoodRating || !hasSubmitted || !reviewId) {
      return null;
    }

    return (
      <ServiceReviewCommentsForm
        contractorName={name}
        onClose={modalContext.hide}
        reviewId={reviewId}
      />
    );
  }

  function renderTipAmountConfirmation() {
    if (!showTipConfirmation || isPartnerContractor) {
      return null;
    }

    let props: TipAmountConfirmationProps;

    if (autoTipSettings.enabled) {
      props = {
        showInformation: true,
        variation: ConfirmationType.AutoTip,
        ...(autoTipSettings.amount
          ? { amount: autoTipSettings.amount / 100 }
          : { percentage: autoTipSettings.percentage! * 100 }),
      };
    } else {
      props = {
        variation: showTipConfirmation,
        ...(tip.amount
          ? { amount: tip.amount as number }
          : { percentage: tip.percentage as number }),
      };
    }

    return (
      <>
        {renderDivider()}

        <TipAmountConfirmation {...props} />
      </>
    );
  }

  function renderAlreadyRatedConfirmation() {
    if (!alreadyRated) {
      return null;
    }

    return (
      <ActionConfirmation
        action={{
          label: t('goBack'),
          onPress: modalContext.hide,
        }}
        description={t('review.alreadyRated.message', {
          date: date({ date: alreadyRated.created_at, format: 'compactedTime' }),
          rating: alreadyRated.rating,
        })}
        icon="minus-circle-outline"
        title={t('review.alreadyRated.title')}
      />
    );
  }

  function renderFooter() {
    const disabled = !rating || hasSubmitted || (isIssueRequired && !issue);
    const loading = isUpdating || hasSubmitted;
    const isBadRatingReady = isBadRating && !isDisputableService;
    const isGoodRatingReady = isGoodRating && (isNoTip || showTipConfirmation);
    const text = isBadRatingReady || isGoodRatingReady ? t('submit') : t('continue');

    return (
      <ModalFooter>
        <Button
          mode="contained"
          trackID="service-review-modal-submit_review"
          onPress={onSubmit}
          loading={loading}
          disabled={disabled}
        >
          {text}
        </Button>
      </ModalFooter>
    );
  }

  const alreadyRatedConfirmation = renderAlreadyRatedConfirmation();
  if (alreadyRatedConfirmation) {
    return alreadyRatedConfirmation;
  }

  const autoTipEnableForm = renderAutoTipEnableForm();
  if (autoTipEnableForm) {
    return autoTipEnableForm;
  }

  const commentsForm = renderCommentsForm();
  if (commentsForm) {
    return commentsForm;
  }

  if ((isLoading || !schedule) && !hasSubmitted) {
    return (
      <ScrollView contentContainerStyle={styles.loadingSection}>
        <ActivityIndicator />
      </ScrollView>
    );
  }

  return (
    <View dataSet={{ media: ids.container }} style={styles.container}>
      <ScrollView dataSet={{ media: ids.contentSection }} style={styles.contentSection}>
        {renderServiceTitle()}
        {renderRatingInput()}
        {renderIssueOptions()}
        {renderTipOptions()}
        {renderTipAmountConfirmation()}
      </ScrollView>

      {renderFooter()}
    </View>
  );
}
