import { useFlags } from 'flagsmith/react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { FeatureFlags, WebRoutes } from '@lawnstarter/customer-modules/enums';
import {
  checkboxParser,
  formatRockInstallationDescription,
  getRockInstallationFieldData,
  MQHowQuotingWorksModal,
  MQSubmitSuccessModal,
} from '@lawnstarter/customer-modules/helpers';
import {
  getTrackingEventSource,
  useOrderServiceViewedTracking,
} from '@lawnstarter/customer-modules/hooks';
import { t } from '@lawnstarter/customer-modules/services';
import {
  orderServices_isUpdating,
  orderServices_submitQuote,
  properties_currentPropertyIdSelector,
} from '@lawnstarter/customer-modules/stores/modules';
import { YardLocations } from '@lawnstarter/ls-constants';
import { Button } from '@lawnstarter/ls-react-common/atoms';
import { ServiceType } from '@lawnstarter/ls-react-common/enums';
import { useApiTracking, useModal } from '@lawnstarter/ls-react-common/hooks';
import { ModalTemplate, RockInstallationForm } from '@lawnstarter/ls-react-common/organisms';

import { OrderServiceHeader, StyledOrderFormContainer } from '@src/components';
import { useDispatch, useRouteNavigation, useSelector } from '@src/hooks';
import { errorService } from '@src/services';

import type {
  RockInstallationFormData,
  RockInstallationFormFieldData,
} from '@lawnstarter/ls-react-common/organisms';

export const OrderRockInstallationScreen = () => {
  const location = useLocation();
  const apiTracking = useApiTracking()!;
  const { control, handleSubmit, watch } = useForm<RockInstallationFormData>();
  const { navigate } = useRouteNavigation();
  const isUpdating = useSelector(orderServices_isUpdating);
  const stepModal = useModal(ModalTemplate.STEPS);
  const QuoteSubmittedModal = useModal(ModalTemplate.ACTION_CONFIRMATION);
  const dispatch = useDispatch();
  const propertyId = useSelector(properties_currentPropertyIdSelector);
  const carouselAbTest = useFlags([FeatureFlags.RECOMMENDATION_CAROUSEL_AB_TEST])[
    FeatureFlags.RECOMMENDATION_CAROUSEL_AB_TEST
  ];

  const source = carouselAbTest.enabled
    ? getTrackingEventSource(location.state?.source, carouselAbTest.value)
    : undefined;

  useOrderServiceViewedTracking(apiTracking, ServiceType.RockInstallation, errorService, source);

  const submit = async (data: RockInstallationFormData) => {
    const { success } = await dispatch(
      orderServices_submitQuote({
        updatedFields: {
          property_id: propertyId,
          service_id: 4813,
          service_str_id: ServiceType.RockInstallation,
          budget: data.budget,
          pendingImages: data.images,
          locations: checkboxParser(data.locations, YardLocations),
          desiredWorkDetails: formatRockInstallationDescription(data),
        },
        source,
      }),
    );

    if (success) {
      return QuoteSubmittedModal.show(MQSubmitSuccessModal(navigate, WebRoutes.services));
    }
  };
  return (
    <StyledOrderFormContainer>
      <OrderServiceHeader
        section={t('forms.quote.request')}
        serviceName={t('forms.rockInstallation.name')}
      />

      <RockInstallationForm
        control={control}
        watch={watch}
        fieldData={getRockInstallationFieldData() as RockInstallationFormFieldData}
        isUpdating={isUpdating}
        infoModal={() => stepModal.show(MQHowQuotingWorksModal())}
      />

      <Button
        trackID="submit"
        testID="submit"
        mode="contained"
        onPress={handleSubmit(submit)}
        loading={isUpdating}
        disabled={isUpdating}
      >
        {t('recommendations.getQuote')}
      </Button>
    </StyledOrderFormContainer>
  );
};
