import { useFlags } from 'flagsmith/react';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  LongGrassQuoteModalTypes,
  useLongGrassQuoteController,
} from '@lawnstarter/customer-modules/controllers';
import { FeatureFlags, WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Icon, Text } from '@lawnstarter/ls-react-common/atoms';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { ModalTemplate } from '@lawnstarter/ls-react-common/organisms';
import {
  LongGrassQuoteReport,
  LongGrassQuoteReportOld,
} from '@lawnstarter/ls-react-common/templates';
import { Tooltip } from '@lawnstarter/ls-react-web-common';

import { DetailsHeader, Loader } from '@src/components';
import { useRouteNavigation } from '@src/hooks';

import { style, StyleLongGrassWrapper } from './styles';

export function LongGrassQuoteReportScreen() {
  const { enabled: isLongGrassQuoteReportScreenV2 } = useFlags([
    FeatureFlags.SERVICES_TAB_IMPROVEMENTS_WEB,
  ])[FeatureFlags.SERVICES_TAB_IMPROVEMENTS_WEB];

  const theme = useAppTheme();
  const styles = useMemo(() => style({ theme }), [theme]);

  const { navigate } = useRouteNavigation();
  const { scheduleId, propertyId, scheduleEventId } = useParams() as {
    propertyId: string;
    scheduleId: string;
    scheduleEventId: string;
  };

  const feedbackModal = useModal(ModalTemplate.FEEDBACK);
  const optionalModal = useModal(ModalTemplate.OPTIONAL_ACTION);
  const actionConfirmationModal = useModal(ModalTemplate.ACTION_CONFIRMATION);

  const onApprove = ({ returnDate }: { returnDate: string }) => {
    optionalModal.show(
      getModalProps({
        returnDate,
        type: LongGrassQuoteModalTypes.ConfirmAction,
        callback: async () => {
          const { success } = await submit({ returnDate });

          if (success) {
            return actionConfirmationModal.show(
              getModalProps({
                returnDate,
                type: LongGrassQuoteModalTypes.ActionPerformed,
                callback: () =>
                  navigate(WebRoutes.serviceDetail, {
                    params: { propertyId, scheduleId },
                  }),
              }),
            );
          }

          actionConfirmationModal.show(
            getModalProps({ type: LongGrassQuoteModalTypes.ActionNotPerformed }),
          );
        },
      }),
    );
  };

  const onReject = () => {
    feedbackModal.show(
      getModalProps({
        type: LongGrassQuoteModalTypes.Reject,
        callback: async (args) => {
          actionConfirmationModal.show(
            await reject({
              reason: args?.answer,
              onRejected: () =>
                navigate(WebRoutes.serviceDetail, {
                  params: { propertyId, scheduleId },
                }),
            }),
          );
        },
      }),
    );
  };

  const {
    labels,
    images,
    control,
    submit,
    reject,
    pricing,
    buttons,
    isLoading,
    returnDates,
    isUpdating,
    getModalProps,
  } = useLongGrassQuoteController({
    onReject,
    onApprove,
    scheduleId: Number(scheduleId),
    propertyId: Number(propertyId),
    scheduleEventId: Number(scheduleEventId),
  });

  // Adding a tooltip because we currently don't have a cross-platform tooltip component
  const patchedPricing = useMemo(() => {
    return {
      ...pricing,
      trustFee: {
        ...pricing.trustFee,
        label: (
          <div className="flex flex-row">
            <Text style={styles.trustFee}>{t('trust_and_safety_fee')}</Text>
            <Tooltip title={t('trust_and_safety_fee_description')} arrow={true}>
              <div>
                <Icon
                  size={theme.sizing.s5}
                  name="help-circle-outline"
                  color={theme.colors.blue.primary}
                />
              </div>
            </Tooltip>
          </div>
        ),
      },
    };
  }, [styles, theme, pricing]);

  if (isLoading) {
    return <Loader testID="loader" />;
  }

  return (
    <div>
      <DetailsHeader title={labels.screenTitle} />

      <StyleLongGrassWrapper>
        {isLongGrassQuoteReportScreenV2 ? (
          <LongGrassQuoteReport
            images={images}
            labels={labels}
            control={control}
            buttons={buttons}
            isUpdating={isUpdating}
            pricing={patchedPricing}
            returnDates={returnDates}
            onLearnMorePress={() =>
              navigate(WebRoutes.learnMoreLongGrassPricing, {
                params: { propertyId, scheduleId },
              })
            }
          />
        ) : (
          <LongGrassQuoteReportOld
            images={images}
            labels={labels}
            control={control}
            buttons={buttons}
            isUpdating={isUpdating}
            pricing={patchedPricing}
            returnDates={returnDates}
            onLearnMorePress={() =>
              navigate(WebRoutes.learnMoreLongGrassPricing, {
                params: { propertyId, scheduleId },
              })
            }
          />
        )}
      </StyleLongGrassWrapper>
    </div>
  );
}
