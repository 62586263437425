import { useMemo } from 'react';
import { View } from 'react-native';
import { useDisputeController } from '@lawnstarter/customer-modules/controllers';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { isPoolCleaningRelatedService } from '@lawnstarter/customer-modules/helpers';
import { t } from '@lawnstarter/customer-modules/services';
import { disputes_declineFix } from '@lawnstarter/customer-modules/stores/modules';
import { useAppTheme } from '@lawnstarter/ls-react-common';
import { Button, Icon, ScrollView, Text } from '@lawnstarter/ls-react-common/atoms';
import { DisputeType, ServiceType } from '@lawnstarter/ls-react-common/enums';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import { DetailsHeader } from '@src/components';
import { useDispatch, useRouteNavigation } from '@src/hooks';
import { useStyles } from '@src/pages/Authorized/Services/DisputeConfirmScreen/styles.ts';

export function DisputeConfirmScreen() {
  const messagesModal = useModal(ModalTemplate.MESSAGES);
  const statusModal = useModal(ModalTemplate.MESSAGE);

  const { title, isUpdating, serviceType, credit, onFixRequest, onCreditRequest, isLoading, type } =
    useDisputeController({ messagesModal, statusModal });
  const { navigate } = useRouteNavigation();

  const theme = useAppTheme();
  const { styles, ids } = useStyles(theme);
  const dispatch = useDispatch();

  async function handleFixRequest() {
    await onFixRequest({
      onDismiss: () =>
        navigate(WebRoutes.services, {
          options: {
            replace: true,
          },
        }),
    });
  }

  async function handleCreditRequest() {
    await onCreditRequest({
      onDismiss: () =>
        navigate(WebRoutes.services, {
          options: {
            replace: true,
          },
        }),
    });
  }

  function handleAbortPoolCleaningFlow() {
    navigate(WebRoutes.services, {
      options: {
        replace: true,
      },
    });
  }

  async function handleAbortMowingFixFlow() {
    const { success } = await dispatch(disputes_declineFix());

    if (success) {
      statusModal.show({
        message: t('flows.reportIssue.youSuccessfullyDeclined'),
        onDismiss: () => navigate(WebRoutes.services, { options: { replace: true } }),
      });
    } else {
      statusModal.show({
        message: t('flows.reportIssue.somethingWentWrongWhileRejectingFix'),
      });
    }
  }

  const content = useMemo(() => {
    if (isPoolCleaningRelatedService(serviceType)) {
      return {
        subtitle: t('flows.reportIssue.wouldYouLikeCredit', { credit }),
        primaryButtonText: t('flows.reportIssue.receiveCredit', { credit }),
        secondaryButtonText: t('noThanks'),
        primaryOnClick: handleCreditRequest,
        secondaryOnClick: handleAbortPoolCleaningFlow,
      };
    }

    if (serviceType === ServiceType.LawnMowing) {
      const isNoWorkDone = type === DisputeType.NoWorkDone;
      return {
        subtitle: isNoWorkDone
          ? t('flows.reportIssue.wouldYouLikeYourProToReturn')
          : t('flows.reportIssue.wouldYouPreferFixOrCredit', { credit }),
        primaryButtonText: isNoWorkDone
          ? t('flows.reportIssue.fixIssue')
          : t('flows.reportIssue.receiveCredit', { credit }),
        secondaryButtonText: isNoWorkDone ? t('noThanks') : t('flows.reportIssue.fixIssue'),
        primaryOnClick: isNoWorkDone ? handleFixRequest : handleCreditRequest,
        secondaryOnClick: isNoWorkDone ? handleAbortMowingFixFlow : handleFixRequest,
      };
    }

    return {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credit, type, serviceType]);

  return (
    <>
      <DetailsHeader showBackButton title={title} />
      <View style={styles.wrapperContainer} dataSet={{ media: ids.wrapperContainer }}>
        <ScrollView>
          <View style={styles.contentContainer}>
            <View style={styles.textContainer}>
              <Icon
                name={'emoticon-sad-outline'}
                size={theme.sizing.s13}
                color={theme.colors.primary}
              />
              <Text style={styles.title} variant={'titleLarge'}>
                {t('flows.reportIssue.weAreIncrediblySorryForTheIssue')}
              </Text>

              <Text style={styles.subtitle}>{content.subtitle}</Text>
            </View>
            <View style={styles.buttonsContainer} dataSet={{ media: ids.buttonsContainer }}>
              <Button
                trackID={'disput_confirm_screen-secondary'}
                mode={'contained-tonal'}
                onPress={content.secondaryOnClick}
                style={styles.buttonSecondary}
                loading={isLoading || isUpdating}
              >
                {content.secondaryButtonText}
              </Button>
              <Button
                trackID={'disput_confirm_screen-primary'}
                mode={'contained'}
                onPress={content.primaryOnClick}
                style={styles.buttonPrimary}
                dataSet={{ media: ids.buttonPrimary }}
                loading={isLoading || isUpdating}
              >
                {content.primaryButtonText}
              </Button>
            </View>
          </View>
        </ScrollView>
      </View>
    </>
  );
}
