import { useFlags } from 'flagsmith/react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { FeatureFlags } from '@lawnstarter/customer-modules/enums';
import {
  getFenceRepairFieldData,
  MQHowQuotingWorksModal,
} from '@lawnstarter/customer-modules/helpers';
import {
  getTrackingEventSource,
  useOrderServiceViewedTracking,
} from '@lawnstarter/customer-modules/hooks';
import { t } from '@lawnstarter/customer-modules/services';
import { orderServices_isUpdating } from '@lawnstarter/customer-modules/stores/modules';
import { Button } from '@lawnstarter/ls-react-common/atoms';
import { ServiceType } from '@lawnstarter/ls-react-common/enums';
import { useApiTracking, useModal } from '@lawnstarter/ls-react-common/hooks';
import { FenceRepairForm, ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import { OrderServiceHeader, StyledOrderFormContainer } from '@src/components';
import { useSelector } from '@src/hooks';
import { errorService } from '@src/services';

import type {
  FenceRepairFormData,
  FenceRepairFormFieldData,
} from '@lawnstarter/ls-react-common/organisms';

export const OrderFenceRepairScreen = () => {
  const location = useLocation();
  const apiTracking = useApiTracking()!;
  const { control, handleSubmit, watch } = useForm<FenceRepairFormData>();
  const isUpdating = useSelector(orderServices_isUpdating);
  const stepModal = useModal(ModalTemplate.STEPS);
  const carouselAbTest = useFlags([FeatureFlags.RECOMMENDATION_CAROUSEL_AB_TEST])[
    FeatureFlags.RECOMMENDATION_CAROUSEL_AB_TEST
  ];

  const source = carouselAbTest.enabled
    ? getTrackingEventSource(location.state?.source, carouselAbTest.value)
    : undefined;

  useOrderServiceViewedTracking(apiTracking, ServiceType.FenceRepair, errorService, source);

  const submit = async () => {
    // TODO: will impliment in PE-49292

    return;
  };

  return (
    <StyledOrderFormContainer>
      <OrderServiceHeader
        section={t('forms.quote.request')}
        serviceName={t('forms.fenceRepair.name')}
      />

      <FenceRepairForm
        control={control}
        watch={watch}
        fieldData={getFenceRepairFieldData() as FenceRepairFormFieldData}
        isUpdating={isUpdating}
        infoModal={() => stepModal.show(MQHowQuotingWorksModal())}
      />

      <Button
        trackID="submit"
        testID="submit"
        mode="contained"
        onPress={handleSubmit(submit)}
        loading={isUpdating}
        disabled={isUpdating}
      >
        {t('recommendations.getQuote')}
      </Button>
    </StyledOrderFormContainer>
  );
};
