import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  LongGrassQuoteModalTypes,
  useLongGrassQuoteControllerV2,
} from '@lawnstarter/customer-modules/controllers';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { ModalTemplate } from '@lawnstarter/ls-react-common/organisms';
import { LongGrassQuoteReport } from '@lawnstarter/ls-react-common/templates';

import { DetailsHeader, Loader } from '@src/components';
import { useAppContext } from '@src/contexts';
import { useRouteNavigation } from '@src/hooks';

import { StyleLongGrassWrapper } from './styles';

export function LongGrassQuoteReportScreenV2() {
  const { navigate } = useRouteNavigation();
  const { scheduleId, propertyId, scheduleEventId } = useParams() as {
    propertyId: string;
    scheduleId: string;
    scheduleEventId: string;
  };

  const feedbackModal = useModal(ModalTemplate.FEEDBACK);
  const optionalModal = useModal(ModalTemplate.OPTIONAL_ACTION);
  const actionConfirmationModal = useModal(ModalTemplate.ACTION_CONFIRMATION);

  const onApprove = ({ returnDate }: { returnDate: string }) => {
    optionalModal.show(
      getModalProps({
        returnDate,
        type: LongGrassQuoteModalTypes.ConfirmAction,
        callback: async () => {
          const { success } = await submit({ returnDate });
          if (success) {
            setTimeout(() => {
              navigate(WebRoutes.serviceDetail, {
                params: { propertyId, scheduleId },
                options: { replace: true },
              });
            }, 1000);
            return actionConfirmationModal.show(
              getModalProps({
                returnDate,
                type: LongGrassQuoteModalTypes.ActionPerformed,
                callback: () => {
                  setTimeout(() => {
                    navigate(WebRoutes.services, { options: { replace: true } });
                  }, 500);
                },
              }),
            );
          }
          actionConfirmationModal.show(
            getModalProps({ type: LongGrassQuoteModalTypes.ActionNotPerformed }),
          );
        },
      }),
    );
  };

  const onReject = () => {
    feedbackModal.show(
      getModalProps({
        type: LongGrassQuoteModalTypes.Reject,
        callback: async (args) => {
          actionConfirmationModal.show(
            await reject({
              reason: args?.answer,
              onRejected: () =>
                navigate(WebRoutes.serviceDetail, {
                  params: { propertyId, scheduleId },
                }),
            }),
          );
        },
      }),
    );
  };

  const {
    labels,
    images,
    control,
    submit,
    reject,
    pricing,
    buttons,
    isLoading,
    returnDates,
    isUpdating,
    getModalProps,
  } = useLongGrassQuoteControllerV2({
    onReject,
    onApprove,
    scheduleId: Number(scheduleId),
    propertyId: Number(propertyId),
    scheduleEventId: Number(scheduleEventId),
  });

  const { setSubTitle, setTitle } = useAppContext();

  useEffect(() => {
    setTitle(t('tabs.myServices'));
    setSubTitle(t('long_grass.report'));
  }, [setSubTitle, setTitle]);

  if (isLoading) {
    return <Loader testID="loader" />;
  }
  return (
    <div>
      <DetailsHeader title={labels.screenTitle} />
      <StyleLongGrassWrapper>
        <LongGrassQuoteReport
          images={images}
          labels={labels}
          control={control}
          buttons={buttons}
          isUpdating={isUpdating}
          pricing={pricing}
          returnDates={returnDates}
          onLearnMorePress={() =>
            navigate(WebRoutes.learnMoreLongGrassPricing, {
              params: { propertyId, scheduleId },
            })
          }
        />
      </StyleLongGrassWrapper>
    </div>
  );
}
