import { useCallback } from 'react';
import { Image } from 'react-native';
import { useParams } from 'react-router-dom';
import PoolWashingImg from '@lawnstarter/customer-modules/assets/modal-images/pool-washing.png';
import { WebRoutes } from '@lawnstarter/customer-modules/enums';
import { t } from '@lawnstarter/customer-modules/services';
import {
  properties_currentPropertySelector,
  propertiesApi,
  useGetPropertyDetailsQuery,
} from '@lawnstarter/customer-modules/stores/modules';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import { Loader, PropertyDetailsQuestionManager } from '@src/components';
import { useDispatch, useRouteNavigation, useSelector } from '@src/hooks';

import type { PropertyDetailsActiveServices } from '@lawnstarter/customer-modules/enums';
import type { PropertyDetails } from '@lawnstarter/customer-modules/types';
import type { ServiceType } from '@lawnstarter/ls-react-common/enums';
import type { ImageSourcePropType } from 'react-native';

export function PropertyDetailsQuestionsScreen() {
  const dispatch = useDispatch();
  const { navigate } = useRouteNavigation();
  const { serviceStrId } = useParams<{ serviceStrId: string }>();
  const property = useSelector(properties_currentPropertySelector);
  const completeModal = useModal(ModalTemplate.ACTION_CONFIRMATION);

  const { data: details, isLoading } = useGetPropertyDetailsQuery(
    {
      input: {
        type: serviceStrId as ServiceType,
        propertyHashId: property?.hash_id,
      },
    },
    { skip: !property?.hash_id },
  );

  const onCompleted = useCallback(() => {
    completeModal.show({
      buttonText: t('goToMyServices'),
      title: t('forms.poolQuestions.done'),
      onDismiss: () => {
        navigate(WebRoutes.services, { options: { replace: true } });

        // Revalidate the property details query
        dispatch(
          propertiesApi.util.invalidateTags([
            {
              type: 'property-details',
              id: `${serviceStrId}-${property?.hash_id}`,
            },
          ]),
        );
      },
      icon: (
        <Image source={PoolWashingImg as ImageSourcePropType} style={{ width: 230, height: 251 }} />
      ),
    });
    // ignore completeModal updates due to .show method infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!property || isLoading) {
    return <Loader />;
  }

  return (
    <div className="p-2 flex-1 flex flex-col">
      <PropertyDetailsQuestionManager
        onCompleted={onCompleted}
        type={serviceStrId as PropertyDetailsActiveServices}
        initialData={details?.getPropertyDetails as PropertyDetails}
      />
    </div>
  );
}
