import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useOrderPoolFlowController } from '@lawnstarter/customer-modules/controllers';
import { FaqTopics } from '@lawnstarter/customer-modules/enums';
import {
  eventEmitter,
  getFaqByTopic,
  getPriceOptionAdditionalInfo,
} from '@lawnstarter/customer-modules/helpers';
import { t } from '@lawnstarter/customer-modules/services';
import { Button, Text } from '@lawnstarter/ls-react-common/atoms';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { ModalTemplate, SelectPriceForm } from '@lawnstarter/ls-react-common/organisms';

import {
  FaqsBottomSheet,
  HelperBottomSheet,
  Loader,
  OrderPoolCleaningLayout,
} from '@src/components';
import { DEVICE_WIDTH_BREAKPOINTS } from '@src/constants';
import { useAppContext } from '@src/contexts';
import { MobileHeaderEvents } from '@src/enums';
import { useWindowSize } from '@src/hooks';

import { BackButtonWrapper, useStyles } from './styles';

import type { PriceOptionAdditionalInfo } from '@lawnstarter/customer-modules/types';
import type { ServiceType } from '@lawnstarter/ls-react-common/enums';
import type { Nullable } from '@lawnstarter/ls-react-common/types';

export function PoolSelectPlanScreen() {
  const { setTitle } = useAppContext();
  const style = useStyles();
  const windowSize = useWindowSize();
  const infoModal = useModal(ModalTemplate.INFO);
  const questionsModal = useModal(ModalTemplate.QUESTIONS);
  const poolQuestions = getFaqByTopic({ topic: FaqTopics.orderPoolCleaning })?.[0];

  const {
    form,
    poolCleaningPrices,
    serviceDescription,
    serviceTitle,
    onSubmitSelectedPlan,
    isFetchingPrices,
  } = useOrderPoolFlowController({
    defaultPlanStartDate: dayjs().add(2, 'day').toDate(),
  });

  const openFaqsModal = () => {
    if (!poolQuestions) {
      return;
    }

    questionsModal.show({
      closeable: true,
      title: poolQuestions.title || t('customerFaq.faq'),
      questions: poolQuestions.questions,
      openFirstQuestion: true,
      openUrl: async ({ target }) => {
        window.open(target, '_blank');
      },
    });
  };

  const [priceOptionAdditionalInfo, setPriceOptionAdditionalInfo] =
    useState<Nullable<PriceOptionAdditionalInfo>>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    setTitle(t('services.poolCleaning.appTitles.selectPlan'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onInfoPress = (key: ServiceType) => {
    const info = getPriceOptionAdditionalInfo(key);
    setPriceOptionAdditionalInfo(info);

    const showBottomSheet = windowSize.width < DEVICE_WIDTH_BREAKPOINTS.tablet.min;
    if (showBottomSheet) {
      eventEmitter.emit(MobileHeaderEvents.onHelpDeskSelectPressEvent);
      return;
    }

    infoModal.show({
      closeable: true,
      showButton: false,
      content: renderAdditionalInformationModal(info!),
    });
  };

  const renderAdditionalInformationModal = ({
    title,
    description,
  }: {
    title: string;
    description: string[];
  }) => (
    <View style={style.modalContainer}>
      <Text variant="headlineSmall" style={style.modalTitle}>
        {title}
      </Text>

      {description.length > 1 && (
        <View style={style.modalContent}>
          {description.map((text, index) => (
            <Text key={index} variant="bodyMedium">
              {text}
            </Text>
          ))}
        </View>
      )}
    </View>
  );

  const renderSheetTitle = useCallback(() => {
    if (!priceOptionAdditionalInfo) {
      return null;
    }

    return (
      <Text variant="titleLarge" style={style.bottomSheetTitle}>
        {priceOptionAdditionalInfo.title}
      </Text>
    );
  }, [priceOptionAdditionalInfo, style]);

  const renderSheetContent = useCallback(() => {
    if (
      !priceOptionAdditionalInfo?.description ||
      priceOptionAdditionalInfo.description.length <= 0
    ) {
      return null;
    }

    return (
      <View>
        {priceOptionAdditionalInfo.description.map((text: string, index: number) => (
          <Text key={index} style={style.bottomSheetText}>
            <Text variant="bodyMedium">{text}</Text>
          </Text>
        ))}
      </View>
    );
  }, [priceOptionAdditionalInfo, style]);

  return (
    <>
      <OrderPoolCleaningLayout
        title={serviceTitle}
        description={serviceDescription}
        onHelpButtonPress={openFaqsModal}
      >
        {isFetchingPrices ? (
          <Loader testID="loader" />
        ) : (
          <>
            <SelectPriceForm
              control={form.control}
              prices={poolCleaningPrices}
              minDate={dayjs().add(1, 'day').toDate()}
              maxDate={dayjs().add(1, 'month').toDate()}
              labels={{
                recommended: t('recommended'),
                preferredDate: t('services.poolCleaning.preferredStartDate'),
                preferredDatePlaceholder: t('services.poolCleaning.pickDate'),
                dateNote: t('services.poolCleaning.selectPlan.dateNote'),
              }}
              onInfoOptionPressed={onInfoPress as (key: string | number) => void}
            />

            <BackButtonWrapper>
              <Button
                trackID="pool_select_plan_screen-order-now"
                onPress={onSubmitSelectedPlan}
                mode="contained"
              >
                {t('confirmOrder')}
              </Button>
            </BackButtonWrapper>
          </>
        )}
      </OrderPoolCleaningLayout>
      <HelperBottomSheet content={renderSheetContent()} title={renderSheetTitle()} />
      <FaqsBottomSheet origin="pool_select_plan_screen" topic={FaqTopics.orderPoolCleaning} />
    </>
  );
}
