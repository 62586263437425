import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useSavePoolQuestionController } from '@lawnstarter/customer-modules/controllers';
import { t } from '@lawnstarter/customer-modules/services';
import { setFontWeight } from '@lawnstarter/ls-react-common';
import { Button, Text, TextArea } from '@lawnstarter/ls-react-common/atoms';
import { useModal } from '@lawnstarter/ls-react-common/hooks';
import { ModalTemplate } from '@lawnstarter/ls-react-common/organisms';

import type { DetailsForPoolCleaning } from '@src/components/organisms/PropertyDetailsQuestionManager/types';

export function PoolComment() {
  const errorModal = useModal(ModalTemplate.ACTION_CONFIRMATION);
  const { control, handleSubmit } = useForm<{ poolComment: string }>();

  const { updateAction, isUpdating } = useSavePoolQuestionController<DetailsForPoolCleaning>({
    errorModal,
    key: 'comments',
  });

  const updateComment = useCallback(
    ({ poolComment = undefined }: { poolComment?: string }) => {
      updateAction(poolComment as string);
    },
    [updateAction],
  );

  return (
    <div className="flex flex-col flex-1 gap-2 md:flex-row">
      <div className="flex flex-col gap-2 md:flex-1">
        <Text variant="headlineSmall" style={{ ...setFontWeight('600') }}>
          {t('forms.poolQuestions.poolComment')}
        </Text>
        <Text>{t('forms.poolQuestions.poolCommentDescription')}</Text>
      </div>

      <div className="flex flex-col gap-4 flex-1">
        <div className="flex flex-col gap-2 flex-[10]">
          <TextArea
            control={control}
            name="poolComment"
            disabled={isUpdating}
            testID="poolCommentTextArea"
            contentStyle={{ height: 115 }}
          />
        </div>

        <div className="flex flex-col self-end justify-end items-end flex-1 w-full">
          <Button
            mode="contained"
            loading={isUpdating}
            style={{ width: '100%' }}
            onPress={handleSubmit(updateComment)}
            trackID="pool_comment_screen-complete"
          >
            {t('complete')}
          </Button>
        </div>
      </div>
    </div>
  );
}
